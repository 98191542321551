
import { isEmpty } from "@/utils/validate"
import roles from "@/types/roles.ts";
import Detail from "./detail.vue";
import { useSelectItemList } from "@/network/lookUp.ts";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useRoute } from "vue-router";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
import { useCityRelTown } from "@/network/chinaArea";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, Detail },
  setup() {
    const user: any = inject("user");
    const id = ref(0);
    const route = useRoute();
    const setting = reactive({ id: id, visible: false });

    const filter = reactive({
      isUnit: true,
      pageNum: 1,
      pageSize: 20,
      keyword: null,
      city: null,
      town: null,
      pcode: null,
      publictype: null,
      batch: null,
      age: null,
      creator: null
    });

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const dicHeritageAge = useSelectItemList("HeritageAge");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const dicHeritageLevel = useSelectItemList("HeritageLevel");
    const showDetail = (_id) => {
      id.value = _id;
      setting.visible = true;
      return false;
    };
    const [isLoading, pagerData, query] = useQueryHeritages(filter);

    const doQuery = () => {
      query().then(p => {
        setTimeout(() => {
          if (isEmpty(filter.keyword) && isEmpty(filter.city) && isEmpty(filter.town) && isEmpty(filter.publictype) && isEmpty(filter.batch) && isEmpty(filter.age)) {
            if (filter.pcode == 1) {
              pagerData.count = 226;
            }
            else if (filter.pcode == 2) {
              pagerData.count = 1966;
            }
          }
        }, 100);


      });
    }
    onMounted(() => {
      doQuery();
    });
    return {
      roles,
      user,
      showDetail,
      id,
      setting,
      dicCity,
      dicTown,
      dicHeritageClass,
      dicHeritageAge,
      dicHeritageBatch,
      dicHeritageLevel,
      isLoading,
      pagerData,
      doQuery,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        doQuery()
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        doQuery();
      },
    };
  },
});
