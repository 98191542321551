import heritage from "@/types/heritage";
import request from "@/utils/request";
import axios from "axios";
import { ElMessage } from "element-plus";
import { copyFrom, copy } from "./common";
import { ref, Ref, reactive } from "vue";

export function useFindHeritage(): any {
  const result = reactive({});
  const isLoading = ref(false);
  const cancel = ref(null);
  const tmpImg = {
    "1978":
      "/Angular/attachments/HeritageUnit/35e74ea68a0d4b68b274b6815ba972db.JPG",
    "1853": "/Angular/attachments/other/8007aaf0a3d16deac44fee7715ea876d.jpg",
  };
  function find(id) {
    isLoading.value = true;

    return request
      .get(`/heritageUnit/${id}`)
      .then((res) => {
        isLoading.value = false;
        Object.assign(result, res, {
          images: tmpImg[id],
        });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function baseSave(data) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (data.id) {
      ps = request
        .put(`/HeritageUnit/BaseManage/${data.id}`, data)
        .then((res) => {
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/HeritageUnit/BaseManage`, data)
        .then((res) => {
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  return [isLoading, result, find, baseSave];
}

export function useQueryHeritages(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  function query() {
    isLoading.value = true;
    return request
      .get(`/heritageUnit/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}

export function useSelectHeritages(): Array<any> {
  const result = ref([]);
  const isLoading = ref(false);

  function query(keyword) {
    isLoading.value = true;
    return request
      .get(`/heritageUnit/selectItemList`, { params: { keyword } })
      .then((res) => {
        isLoading.value = false;
        result.value = res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}
