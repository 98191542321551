<template>
  <Base title="文保单位清单" titleIcon="el-icon-s-claim">
  <div>
    <div class="search-row">
      <el-space>
        <el-select v-model="filter.city" placeholder="市级" clearable>
          <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="filter.town" placeholder="区县" clearable>
          <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="filter.pcode" placeholder="级别" clearable>
          <el-option v-for="item in dicHeritageLevel" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>
        <el-select v-model="filter.publictype" placeholder="公布类型" clearable>
          <el-option v-for="item in dicHeritageClass" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>
        <el-select v-model="filter.age" placeholder="年代" clearable>
          <el-option v-for="item in dicHeritageAge" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>
        <!-- <el-select v-model="filter.batch" placeholder="批次" clearable>
          <el-option v-for="item in dicHeritageBatch" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select> -->
        <el-input placeholder="名称" v-model="filter.keyword" style="width: 500px">
          <template #prepend> 关键字 </template>
        </el-input>
        <el-button type="primary" icon="el-icon-search" style="margin-left: 5px" @click="() => {
          filter.pageNum = 1;
          doQuery();
        }
          ">查询</el-button>
      </el-space>
    </div>
  </div>
  <el-table :data="pagerData.list" v-loading="isLoading">
    <!-- <el-table-column label="单位编号" prop="heritage_Code"></el-table-column> -->
    <el-table-column label="单位名称" prop="heritage_Name">
      <template #default="{ row: item }">
        <el-link href="javascript:void(0)" icon="el-icon-view" @click="showDetail(item.id)" type="primary">{{
          item.heritage_Name
        }}</el-link>
      </template>
    </el-table-column>
    <el-table-column label="所在市县">
      <template #default="{ row: item }">
        {{ item.city_Name }}
        {{ item.county_Name }}
      </template>
    </el-table-column>
    <el-table-column label="级别" prop="heritageLevel_Name"></el-table-column>
    <el-table-column label="批次" prop="heritageBatch_Name"></el-table-column>
    <el-table-column label="公布类型" prop="heritageClass_Name"></el-table-column>
    <!-- <el-table-column label="机构类型" prop="institute_Type_Name"></el-table-column> -->
    <el-table-column label="类型明细" prop="heritageType_Name"></el-table-column>
    <el-table-column label="公布时代" prop="heritageAge_Name"></el-table-column>
    <el-table-column label="操作" v-if="user.inRoles(roles.prov)">
      <template #default="{ row: item }">
        <el-button icon="el-icon-edit" type="text"
          @click="() => $router.push(`/HeritageUnit/BaseManage/${item.id}?redirect=/HeritageUnit/index`)">修改
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination style="padding-left: 5px" @current-change="handleCurrentChange" @size-change="handleSizeChange"
    v-model:page-size="filter.pageSize" :total="pagerData.count" layout="sizes,total, prev, pager, next"
    v-model:currentPage="filter.pageNum"></el-pagination>
  <Detail :setting="setting" :id="id" v-if="setting.visible"></Detail>
  </Base>
</template>

<script lang="ts">
import { isEmpty } from "@/utils/validate"
import roles from "@/types/roles.ts";
import Detail from "./detail.vue";
import { useSelectItemList } from "@/network/lookUp.ts";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useRoute } from "vue-router";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
import { useCityRelTown } from "@/network/chinaArea";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, Detail },
  setup() {
    const user: any = inject("user");
    const id = ref(0);
    const route = useRoute();
    const setting = reactive({ id: id, visible: false });

    const filter = reactive({
      isUnit: true,
      pageNum: 1,
      pageSize: 20,
      keyword: null,
      city: null,
      town: null,
      pcode: null,
      publictype: null,
      batch: null,
      age: null,
      creator: null
    });

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const dicHeritageAge = useSelectItemList("HeritageAge");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const dicHeritageLevel = useSelectItemList("HeritageLevel");
    const showDetail = (_id) => {
      id.value = _id;
      setting.visible = true;
      return false;
    };
    const [isLoading, pagerData, query] = useQueryHeritages(filter);

    const doQuery = () => {
      query().then(p => {
        setTimeout(() => {
          if (isEmpty(filter.keyword) && isEmpty(filter.city) && isEmpty(filter.town) && isEmpty(filter.publictype) && isEmpty(filter.batch) && isEmpty(filter.age)) {
            if (filter.pcode == 1) {
              pagerData.count = 226;
            }
            else if (filter.pcode == 2) {
              pagerData.count = 1966;
            }
          }
        }, 100);


      });
    }
    onMounted(() => {
      doQuery();
    });
    return {
      roles,
      user,
      showDetail,
      id,
      setting,
      dicCity,
      dicTown,
      dicHeritageClass,
      dicHeritageAge,
      dicHeritageBatch,
      dicHeritageLevel,
      isLoading,
      pagerData,
      doQuery,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        doQuery()
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        doQuery();
      },
    };
  },
});
</script>

<style scoped></style>