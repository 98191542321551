import heritage from "@/types/heritage";
import request from "@/utils/request";
import { ref, Ref, reactive, defineAsyncComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { copyFrom } from "./common";
export const DocContent = defineAsyncComponent(
  () => import("@/views/officialDoc/showDocContent.vue")
);

export const DocViewBtn = defineAsyncComponent(
  () => import("@/views/officialDoc/showDocContentBtn.vue")
);


export const DocComponents = {
  DocContent,
  DocViewBtn
};

export function useCheckUnique() {
  const isLoading = ref(false);

  const check = (id: number, usedId: number) => {
    return request.get(`/officialDoc/checkUnique/${id}/${usedId}`).then((v) => {
      isLoading.value = false;
      return v;
    });
  };

  return {
    isLoading,
    check,
  };
}
export function useQueryOfficialDocs(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  function query() {
    isLoading.value = true;
    request
      .get(`/officialDoc/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}

// export function useAmountReview(id: Ref<number>): any {
//   const isLoading = ref(false);
//   const result = reactive({});
//   const cancel = ref(null);
//   function find() {
//     if (id.value) {
//       const cancelTokenSource = axios.CancelToken.source();
//       cancel.value = cancelTokenSource;
//       isLoading.value = true;

//       return request
//         .get(`/projectFundApply/findAmountReview/${id.value}`, {
//           cancelToken: cancelTokenSource.token,
//         })
//         .then((res) => {
//           Object.assign(result, res);
//           isLoading.value = false;
//           return res;
//         });
//     }
//   }

//   function save() {
//     isLoading.value = true;
//     let ps = null;
//     if (id.value != 0) {
//       const cancelTokenSource = axios.CancelToken.source();
//       cancel.value = cancelTokenSource;
//       ps = request
//         .put(`/projectFundApply/saveAmountReview/${id.value}`, result)
//         .then((res) => {
//           Object.assign(result, res);
//           isLoading.value = false;
//           ElMessage.success({ message: "数据已保存", type: "success" });
//         })
//         .catch((e) => {
//           isLoading.value = false;
//           throw e;
//         });
//     }

//     return ps;
//   }

//   //saveFlag null 提交 true保存，false 退回
//   function submit(idValue = null) {
//     isLoading.value = true;
//     let ps = null;
//     if (id.value != 0) {
//       const cancelTokenSource = axios.CancelToken.source();
//       cancel.value = cancelTokenSource;
//       ps = request
//         .put(
//           `/projectFundApply/submitAmountReview/${
//             idValue == null ? id.value : idValue
//           }`
//         )
//         .then((res) => {
//           Object.assign(result, res);
//           isLoading.value = false;
//           ElMessage.success({
//             message: `操作成功！`,
//             type: "success",
//           });
//         })
//         .catch((e) => {
//           isLoading.value = false;
//           throw e;
//         });
//     }
//     return ps;
//   }
//   return [isLoading, result, find, save, submit, cancel];
// }

export function useOfficialDoc(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);
  const empty = {
    id: 0,
    isMuliNo: false,
    muliNoKey: null,
    doc_Org: null,
    doc_Title: null,
    doc_Compile_No: null,
    filePath: null,
    doc_Type: null,
    doc_Category: null,
    doc_Year: null,
    doc_Serial_No: null,
  };
  const result = reactive(copyFrom(empty));

  function changeStatus(idValue: number, status, memo): void {
    isLoading.value = true;
    return request
      .put(`/officialDoc/changeStatus/${idValue}/${status}`, { memo })
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function toggleUnique(idValue: number): void {
    isLoading.value = true;
    return request
      .put(`/officialDoc/toggleUnique/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function remove(idValue: number): void {
    isLoading.value = true;
    return request
      .delete(`/officialDoc/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      return request
        .get(`/officialDoc/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          copyFrom(result, empty, res);

          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/officialDoc/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
          return res;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/officialDoc`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
          return res;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  return [isLoading, result, find, save, changeStatus, toggleUnique, remove];
}

export function useInitOfficialDocObj(...doc) {
  const arr = doc.map((p) => {
    return reactive(Object.assign({}, p));
  });
  function empty(obj) {
    Object.assign(obj, {});
  }
  function reset(obj) {
    Object.assign(obj, doc[arr.findIndex((p) => p == obj)]);
  }
  return [...arr, empty, reset];
}
export function useQueryActionHistory(): Array<any> {
  const result = ref([]);
  function query(id) {
    request.get(`/officialDoc/logHistory/${id}`).then((res) => {
      result.value = res;
    });
  }
  return [result, query];
}
export function usePageQueryDocTypes(filter): Array<any> {
  const isLoading = ref(false);
  const result = ref([]);

  function query(): void {
    isLoading.value = true;
    request
      .get(`/officialDoc/docTypesPageQuery`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.value = res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  return [isLoading, result, query];
}

export const useDocType = (id) => {
  const isLoading = ref(false);
  const cancel = ref(null);
  const result = reactive({ label: "", sort_Seq: 0 });
  function remove(_id) {
    return request
      .delete(`/officialDoc/docType/${_id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "数据已删除", type: "success" });
      })
      .catch((e) => {
        isLoading.value = false;
        throw e;
      });
  }
  function find() {
    let ps = null;
    if (id.value) {
      ps = request
        .get(`/officialDoc/docType/${id.value}`, result)
        .then((res) => {
          Object.assign(result, res);
          isLoading.value = false;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = Promise.resolve({
        label: "",
        sort_Seq: null,
      });
    }

    return ps;
  }
  function save() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/officialDoc/docType/${id.value}`, result)
        .then((res) => {
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/officialDoc/docType`, result)
        .then((res) => {
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }
  return { isLoading, result, cancel, find, save, remove };
};

export function useGetOfficialDocTypes(): Array<any> {
  const isLoading = ref(false);
  const result = ref([]);

  function query(): void {
    isLoading.value = true;
    request
      .get(`/officialDoc/docTypes`)
      .then((res) => {
        isLoading.value = false;
        result.value = res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  query();
  return [isLoading, result, query];
}

export function useGetOfficialDocYears(): Array<any> {
  const isLoading = ref(false);
  const result = ref([]);

  function query(id, catagory): void {
    isLoading.value = true;
    request
      .get(`/officialDoc/docYear/${id}/${catagory}`)
      .then((res) => {
        isLoading.value = false;
        result.value = res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}

export function useGetOfficialDocNumbers(): Array<any> {
  const isLoading = ref(false);
  const result = ref([]);

  function query(docType, docYear, catagory): void {
    isLoading.value = true;

    request
      .get(`/officialDoc/docNumber/${docType}/${docYear}/${catagory}`)
      .then((res) => {
        isLoading.value = false;
        result.value = res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}
